import React, { useState } from "react";
import "../stylesheets/Header.css";
import Menu from "./Menu";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <header className="header">
      <div className="hamburger" onClick={handleClick}>
        <div className={`line ${isOpen ? "spin" : ""}`}></div>
        <div className={`line ${isOpen ? "spin" : ""}`}></div>
        <div className={`line ${isOpen ? "spin" : ""}`}></div>
      </div>
      <h1>Header Title</h1>
      {isOpen && <Menu onClose={handleClose} />}
    </header>
  );
};

export default Header;
