import React from "react";
import "../stylesheets/Menu.css";

const Menu = ({ onClose }) => {
  const handleScrollTo = (id) => {
    onClose(); // Close the menu
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="menu">
      <div className="close-icon" onClick={onClose}><h1>X</h1></div>
      <ul>
        <h2 onClick={() => handleScrollTo("section1")}>What we do</h2>
        <h2 onClick={() => handleScrollTo("section2")}>The team</h2>
        <h2 onClick={() => handleScrollTo("services")}>Services we provide</h2>
        <h2 onClick={() => handleScrollTo("section4")}>Contact us</h2>
      </ul>
    </div>
  );
};

export default Menu;