import React from "react";
import "../stylesheets/PageBreak.css";

const PageBreak = ({ text }) => {
  return (
    <div style={{margin: "0", padding: "0"}} className="page-break">
      <hr style={{margin: "0", padding: "0"}}/>
      <div className="page-break-text">{text}</div>
      <hr style={{margin: "0", padding: "0"}} />
    </div>
  );
};

export default PageBreak;