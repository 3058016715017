import React, { useState } from "react";
import "../stylesheets/SplitScreenRight.css";

const SplitScreenRight = ({ imageUrl, titles, texts, showButton }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getFirstSentence = (text) => {
    const match = text.match(/[^.!?]*[.!?]/);
    return match ? match : text;
  };

  return (
    <div className="split-screen-right">
      <div className="left-pane-ssr">
        {titles.map((title, index) => (
          <div className="text-block" key={index}>
            <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
              {React.isValidElement(title) ? title : <span>{title}</span>}
            </h1>
            <p>
              {expanded
                ? React.isValidElement(texts[index])
                  ? texts[index]
                  : texts[index]
                : React.isValidElement(texts[index])
                ? texts[index]
                : getFirstSentence(texts[index])}
            </p>
          </div>
        ))}
        {showButton && (
          <button
            onClick={handleExpandClick}
            style={{
              background: "none",
              border: "none",
              color: "darkblue",
              cursor: "pointer",
            }}
            aria-label={expanded ? "Show Less" : "Show More"}
          >
            {expanded ? "Show Less" : "Show More"}
          </button>
        )}
      </div>
      <div style={{ padding: "0" }} className="right-pane">
        <img src={imageUrl} alt="Description" />
      </div>
    </div>
  );
};

export default SplitScreenRight;