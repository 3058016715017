import React from "react";
import bp_qr from "../assets/bp_qr.svg";
import "../stylesheets/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section business-info">
          <h2>Browning Projects LLC</h2>
          <p>7616 Bertito Lane, Springfield, Virginia 22153</p>
        </div>
        <div className="footer-section contact-info">
          <h2>Contact Us</h2>
          <p>
            <a href="mailto:info@browningprojects.com">
              info@browningprojects.com
            </a>
          </p>
          <p>(703) 984-9428</p>
          <img
          className="qr"
          src={bp_qr}
          alt="QR"
          onError={(e) => (e.target.src = "defaultImage.jpg")}
        />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
