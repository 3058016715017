import React, { Component } from "react";
import "../stylesheets/App.css";
import SplitScreen from "./SplitScreen";
import SplitScreenRight from "./SplitScreenRight";
import ScrollToTopButton from "./ScrollToTopButton";
import ContactMenu from "./ContactMenu";
import FourSquareImage from "./FourSquareImage";
import SmallScreenComponent from "./SmallScreenComponent"; // Import your small screen component
import Carousel from "./Carousel";
import Header from "./Header";
import PageBreak from "./PageBreak";
import Footer from "./Footer";
import bp_logo from "../assets/bp_logo.png";
import meet_jazz from "../assets/Jasmin.png";
import meet_james from "../assets/James.png";
import the_team from "../assets/jasmin_james.jpeg";
import consulting from "../assets/Consulting_services.png";
import e2ec from "../assets/E2E_PM.png";
import b2g from "../assets/B2G.png";
import it from "../assets/ITsolutions.png";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLargeScreen: window.innerWidth > 768,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ isLargeScreen: window.innerWidth > 768 });
  };

  render() {
    const { isLargeScreen } = this.state;
    const topTitles = ["What we do"];
    const topTexts = [
      `
      With over 15 years of experience in both the private and public sectors, our veteran-owned business specializes in delivering large-scale projects and strategic advisory services across the U.S. and Europe. We specialize in executive consulting, cloud computing, process optimization, and industry-specific solutions for sectors like grocery retail. Our tailored approach drives innovation, improves efficiency, and ensures sustainable growth. 

Authorized to operate in the US and Europe without restrictions, we bring specialized expertise in the German market, leveraging local business knowledge and language skills to help clients navigate European opportunities. We also support international government operations, including the launch and maintenance of overseas facilities, using cutting-edge cloud technologies and infrastructure management to ensure seamless, scalable results. 

If you're looking for projects delivered with confidence, or operations launched or maintained both nationally and internationally, our team is dedicated to delivering impactful, results-driven solutions. Partner with us to achieve your strategic goals and create lasting value. `,
    ];
    return (
      <div>
        <Header />
        <SplitScreen
          imageUrl={bp_logo}
          titles={topTitles}
          texts={topTexts}
          showButton={true}
        />
        <div id="section2">
          <PageBreak text="We bring solutions to the private and public sectors." />
          <SplitScreenRight
            imageUrl={the_team}
            titles={["From Virginia, with international reach"]}
            texts={[
              "Browning Projects was founded in 2023 with the goal of providing services for not only large clients, but small businesses, and non-profits. We have a broad and deep background in management and technology. Reach out for your next project.",
            ]}
            showButton={false}
          />
        </div>
        <div id="services">
          {isLargeScreen ? (
            <FourSquareImage
              image1={it}
              image2={e2ec}
              image3={b2g}
              image4={consulting}
            />
          ) : (
            <SmallScreenComponent
              image1={it}
              image2={e2ec}
              image3={b2g}
              image4={consulting}
            />
          )}
        </div>

        <div>
          <SplitScreenRight
            imageUrl={meet_jazz}
            titles={["Jasmin"]}
            texts={[`
              Jasmin has over a decade of experience in large-scale grocery retail operations, where she developed a strong foundation in leadership and operational management, progressing from associate roles to district manager at Aldi US.
              This extensive experience has honed her ability to drive results and manage people and processes effectively across all levels of an organization.
              Her competitive nature and drive for excellence enable her to consistently achieve optimal outcomes for all stakeholders involved.
              At Lidl, Europe’s largest retailer and the third-largest global retail group, Jasmin expanded her expertise by working across multiple countries, including the U.S. and Germany.
              During her tenure, she held various roles in both the Sales Organization and Project Management Office, ultimately overseeing the international bakery goods sector at Lidl's global headquarters.
              In this capacity, she led process optimization initiatives and regularly advised the Lidl Board on strategic developments.
              Jasmin is highly skilled in executive consulting and is adept at communicating across all organizational levels, including C-suite executives, and navigating diverse cultural contexts.
              She was instrumental in launching operations for Lidl in both the U.S. and Serbia, where she successfully managed large, cross-functional projects and teams and delivered complex deliverables within tight deadlines.
              Currently serving as Chief Operating Officer at Browning Projects, Jasmin is responsible for managing projects and overseeing day-to-day operations.
              She works closely with private clients, networking agencies, and investors, providing project management advisory services, leading research projects, and offering Project Management Professional (PMP) exam preparation and advisory.
              Jasmin's diverse background equips her to effectively navigate both European and U.S. operations, with a deep understanding of cultural and regulatory nuances. 
              She holds multiple professional certifications, including Project Management Professional (PMP), Certified Scrum Master (CSM), and Certified Cloud Practitioner (CLF-C02), further reinforcing her qualifications to lead in today’s fast-paced, global business environment.
              She is also a member of the Steering Committee for Women in Technology.
              In her spare time, she actively engages with the community by teaching elementary school children about cloud computing and organizing running groups for teenagers.
            `]}
            showButton={true}
          />
        </div>

        <PageBreak text="" />
        <div>
          <SplitScreen
            imageUrl={meet_james}
            titles={["James"]}
            texts={[
              <div>
                <h4>Co-Founder and CEO</h4>
                <p>
                  Senior Software Engineer, experience both in the private and
                  government sector​
                </p>
                <p>
                  Portfolio includes projects in iOS/Android, web, API,
                  middleware, and backend development
                </p>
                <p>Officer in the US Naval Reserve​</p>
              </div>,
            ]}
            showButton={false}
          />
        </div>

        <div id="section3">
          <PageBreak text="Who we work with" />
          <Carousel />
        </div>
        <div id="section4">
          <PageBreak text="Contact us" />
          <ContactMenu />
        </div>
        <hr />
        <Footer />
        <ScrollToTopButton />
      </div>
    );
  }
}

export default App;
