import React from "react";
import "../stylesheets/Carousel.css";
import rca from "../assets/rca.jpeg";
import fcps from "../assets/fcps.png";
import glg from "../assets/glg.png";
import varsity_tutors from "../assets/varsity_tutors.png";

const Carousel = () => {
  const images = [
    rca,
    fcps,
    glg,
    varsity_tutors, // Add more images as needed
  ];

  return (
    <div className="carousel">
      <div className="carousel-grid">
        {images.map((image, index) => (
          <div className="carousel-item" key={index}>
            <img src={image} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
