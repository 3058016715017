import React, { useState } from "react";
import "../stylesheets/ContactMenu.css";

const ContactMenu = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://2mqt62e47b.execute-api.us-east-1.amazonaws.com/default/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        alert("Message sent successfully!");
        setFormData({ fullName: "", email: "", phone: "", message: "" });
      } else {
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending the message.");
    }
  };

  return (
    <div className="contact-menu">
            
      <form onSubmit={handleSubmit}>
                
        <div style={{ margin: "0", padding: "0" }} className="form-group">
                    <label htmlFor="fullName">Full Name</label>
                    
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
                  
        </div>
                
        <div className="form-group" style={{ margin: "0", padding: "0" }}>
                    <label htmlFor="email">Email Address</label>
                    
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
                  
        </div>
                
        <div className="form-group" style={{ margin: "0", padding: "0" }}>
                    <label htmlFor="phone">Phone Number</label>
                    
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
                  
        </div>
                
        <div className="form-group" style={{ margin: "0", padding: "0" }}>
                    <label htmlFor="message">Message</label>
                    
          <textarea
            style={{ margin: "0" }}
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
                  
        </div>
                <button type="submit">Send</button>
              
      </form>
          
    </div>
  );
};

export default ContactMenu;
