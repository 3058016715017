import React, { useState } from "react";
import "../stylesheets/SplitScreen.css";

const SplitScreen = ({ imageUrl, titles, texts, showButton }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getFirstSentence = (text) => {
    const match = text.match(/[^.!?]*[.!?]/);
    return match ? match : text;
  };

  return (
    <div className="split-screen">
      <div style={{ padding: "0", marginTop: "0px" }} className="left-pane">
        <img
          src={imageUrl}
          alt="Description"
          onError={(e) => (e.target.src = "defaultImage.jpg")}
        />
      </div>
      <div id="section1" className="right-pane">
        {titles.map((title, index) => (
          <div className="text-block" key={index}>
            <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
              {React.isValidElement(title) ? title : <span>{title}</span>}
            </h1>
            <p>
              {expanded
                ? React.isValidElement(texts[index])
                  ? texts[index]
                  : texts[index]
                : React.isValidElement(texts[index])
                ? texts[index]
                : getFirstSentence(texts[index])}
            </p>
          </div>
        ))}
        {showButton && (
          <button
            onClick={handleExpandClick}
            style={{
              background: "none",
              border: "none",
              color: "darkblue",
              cursor: "pointer",
            }}
            aria-label={expanded ? "Show Less" : "Show More"}
          >
            {expanded ? "Show Less" : "Show More"}
          </button>
        )}
      </div>
    </div>
  );
};

export default SplitScreen;